import { languages } from "../helper";
import Cookies from "js-cookie";

const cookieLanguage = Cookies.get("i18next");

export default {
  supportedLngs: Object.keys(languages),
  fallbackLng: cookieLanguage ? cookieLanguage : "en",
  defaultNS: "common",
  // Disabling suspense is recommended
  react: { useSuspense: false },
};
